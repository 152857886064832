import { inject } from '@angular/core';
import { CrmTranslateService } from 'common-module/translate';
import { DateTime } from 'luxon';
import { catchError, EMPTY, switchMap } from 'rxjs';

import { PatientsApiService } from '~/api/patients/patients-api.service';
import {
  CocmScheduleSessionTodoModel,
  ScheduleSessionTodoType,
} from '~/api/todo/model/cocm-schedule-session.model';
import { UserModel } from '~/api/user/model/user.model';
import { openScheduleVisitFn } from '~/shared/modal/schedule-visit/open-schedule-visit';
import { parseDateTime } from '~/shared/utils/parse-date-time';
import { BaseTodoResolver } from '~/shared/crm/layout/right-sidebar/todos/resolvers/base-todo.resolver';

import { formatAfterExpirationFactory } from '../factories/format-after-expiration.factory';
import { formatBeforeExpirationFactory } from '../factories/format-before-expiration.factory';
import { TodoItem } from '../todo.item';
import { ExtendedCocmScheduleSessionTodoModel } from '../types/cocm-schedule-session.todo';

/**
 * Class which resolves and maps cocm schedule session TO-DOs
 */
export class CocmScheduleSessionTodosResolver extends BaseTodoResolver {
  private patient = inject(PatientsApiService);
  private translate = inject(CrmTranslateService);
  private formatBeforeExpiration = formatBeforeExpirationFactory();
  private formatAfterExpiration = formatAfterExpirationFactory();
  private scheduleVisit = openScheduleVisitFn();

  mapToExtendedTodo(
    todo: CocmScheduleSessionTodoModel,
    users: UserModel[],
  ): ExtendedCocmScheduleSessionTodoModel {
    return {
      ...todo,
      user: users.find(({ _id }) => _id === todo.meta.user),
    };
  }

  mapToItem(
    todo: ExtendedCocmScheduleSessionTodoModel,
  ): TodoItem<ExtendedCocmScheduleSessionTodoModel> {
    const { user, _id, endTime } = todo;

    if (!user) {
      throw new Error(`No user for todo '${todo._id}'`);
    }

    const type = this.getType(todo.type);

    return {
      id: _id,
      type: 'actual',
      user,
      icon: 'schedule-line',
      category: this.translate.get('todos.category.schedule-thv'),
      expireAt: parseDateTime(endTime),
      messages: [
        {
          message: this.translate.get(`todos.scheduleSession.${type}.message`),
        },
      ],
      title: this.translate.get(`todos.scheduleSession.${type}.title`),
      resolveStatus: ({ expireAt }) =>
        expireAt < DateTime.now() ? 'overdue' : 'active',
      resolveExpiration: ({ expireAt }) => {
        const now = DateTime.now();

        if (expireAt > now) {
          return this.formatBeforeExpiration(
            expireAt.diff(now, [
              'weeks',
              'days',
              'hours',
              'minutes',
              'seconds',
              'milliseconds',
            ]),
          );
        }

        return this.formatAfterExpiration(
          now.diff(expireAt, [
            'weeks',
            'days',
            'hours',
            'minutes',
            'seconds',
            'milliseconds',
          ]),
        );
      },
      action: {
        id: `schedule-${type}-session-${_id}`,
        title: `todos.scheduleSession.action`,
        icon: 'icons:schedule-line',
        size: 'small',
        type: 'primary',
        action: () => this.schedule(_id, user, type),
      },
      dropdownActions: [this.getMarkAsCompletedAction(_id)],
      model: todo,
    };
  }

  private getType(type: ScheduleSessionTodoType) {
    switch (type) {
      case 'cocm_schedule_therapy_session':
        return 'therapy';
      case 'cocm_schedule_psychiatric_session':
        return 'psychiatric';
    }
  }

  private getServiceTypeByType(type: 'therapy' | 'psychiatric') {
    switch (type) {
      case 'therapy':
        return 'Psychotherapy';
      case 'psychiatric':
        return 'Psychiatry';
    }
  }

  private schedule(
    id: string,
    user: UserModel,
    type: 'therapy' | 'psychiatric',
  ) {
    this.patient
      .getByOwner(user._id)
      .pipe(
        switchMap((patient) =>
          this.scheduleVisit({
            user,
            patient: patient._id,
            selectDefaultOrFirstCommunity: true,
            serviceType: {
              id: '-1',
              type: { name: 'Mental / BH visit', translations: {} },
            },
            serviceType2: {
              id: '-1',
              type: { name: this.getServiceTypeByType(type), translations: {} },
            },
          }),
        ),
        switchMap(() =>
          this.closeTodo(id, `todos.scheduleSession.${type}.success`),
        ),
        catchError(() => EMPTY),
      )
      .subscribe();
  }
}
