import {
  effect,
  inject,
  Injectable,
  runInInjectionContext,
  signal,
  untracked,
} from '@angular/core';
import { CrmDictionary, CrmResolvable } from 'common-module/core/types';
import { crmFillIn } from 'common-module/list';
import {
  catchError,
  EMPTY,
  finalize,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { assign, intersection } from 'lodash-es';
import { DateTime } from 'luxon';

import { EventsApiService } from '~/api/events/events-api.service';
import { PatientsAdmissionsApiService } from '~/api/patients/admissions/patients-admissions-api.service';
import { isAssessmentTodo } from '~/api/todo/model/assessment-todo.model';
import { isCocmScheduleSessionTodo } from '~/api/todo/model/cocm-schedule-session.model';
import { isCocmTreatmentPlanTodo } from '~/api/todo/model/cocm-treatment-plan-todo.model';
import { isInitialContactTodo } from '~/api/todo/model/initial-contact-todo.model';
import { isRescheduleTelehealthVisitTodoModel } from '~/api/todo/model/reschedule-telehealth-visit-todo.model';
import { isScheduledCallTodo } from '~/api/todo/model/scheduled-call-todo.model';
import { isTocFollowUpVisitTodoModel } from '~/api/todo/model/toc-follow-up-visit-todo.model';
import { TodoModel } from '~/api/todo/model/todo.model';
import { TodoApiService } from '~/api/todo/todo-api.service';
import { UserModel } from '~/api/user/model/user.model';
import { UserService } from '~/api/user/user.service';
import { EventBusService } from '~/shared/services/event-bus.service';

import {
  RightSidebarData,
  RightSidebarItemProvider,
} from '../right-sidebar.item';

import { AssessmentTodosResolver } from './resolvers/assessment-todos.resolver';
import { CocmScheduleSessionTodosResolver } from './resolvers/cocm-schedule-session.resolver';
import { CocmTreatmentPlanTodosResolver } from './resolvers/cocm-treatment-plan-todos.resolver';
import { InitialContactTodosResolver } from './resolvers/initial-contact-todos.resolver';
import { RescheduleTelehealthVisitTodosResolver } from './resolvers/reschedule-telehealth-visit-todos.resolver';
import { ScheduledCallTodosResolver } from './resolvers/scheduled-call-todos.resolver';
import { TocFollowUpVisitTodosResolver } from './resolvers/toc-follow-up-visit-todos.resolver';
import { TodoCategory, todoTypesByCategory } from './todo.category';
import { ExtendedTodoModel, TodoItem } from './todo.item';
import { TodosComponent } from './todos.component';
import { TodosFilter, TodosFilterOptions } from './todos.filter';

@Injectable({ providedIn: 'root' })
export class TodosProvider extends RightSidebarItemProvider {
  readonly items = signal<TodoItem<ExtendedTodoModel>[]>([]);
  readonly total = signal<number | null>(null);
  readonly filter = signal<TodosFilter>({ category: 'all' });
  readonly filterOptions = signal<TodosFilterOptions>({});
  readonly isLoading = signal(false);

  private skip = 0;
  private limit = 10;

  private admissions = inject(PatientsAdmissionsApiService);
  private user = inject(UserService);
  private events = inject(EventsApiService);
  private todo = inject(TodoApiService);
  private eventBus = inject(EventBusService);

  private initialContactTodosResolver!: InitialContactTodosResolver;
  private tocFollowUpVisitTodosResolver!: TocFollowUpVisitTodosResolver;
  private rescheduleTelehealthVisitTodosResolver!: RescheduleTelehealthVisitTodosResolver;
  private cocmTreatmentPlanTodosResolver!: CocmTreatmentPlanTodosResolver;
  private cocmScheduleSessionTodosResolver!: CocmScheduleSessionTodosResolver;
  private assessmentTodosResolver!: AssessmentTodosResolver;
  private scheduledCallTodosResolver!: ScheduledCallTodosResolver;

  override init(): CrmResolvable<RightSidebarData> {
    runInInjectionContext(this.inputs.injector, () => {
      this.initialContactTodosResolver = new InitialContactTodosResolver();
      this.cocmTreatmentPlanTodosResolver =
        new CocmTreatmentPlanTodosResolver();
      this.tocFollowUpVisitTodosResolver = new TocFollowUpVisitTodosResolver();
      this.rescheduleTelehealthVisitTodosResolver =
        new RescheduleTelehealthVisitTodosResolver();
      this.assessmentTodosResolver = new AssessmentTodosResolver();
      this.scheduledCallTodosResolver = new ScheduledCallTodosResolver();
      this.cocmScheduleSessionTodosResolver =
        new CocmScheduleSessionTodosResolver();

      effect(() => {
        const filter = this.filter();

        untracked(() => {
          this.destroy();
          this.loadData(filter).subscribe();
        });
      });
    });

    this.eventBus
      .on<string>('TODO:COMPLETED')
      .pipe(
        takeUntil(this.inputs.destroy$),
        switchMap((id) => this.close(id)),
      )
      .subscribe();

    return of({ render: { content: TodosComponent } });
  }

  override destroy() {
    this.skip = 0;
    this.total.set(null);
    this.items.set([]);
  }

  removeItem(id: string) {
    this.total.update((state) => Math.max((state ?? 0) - 1, 0));
    this.items.update((state) =>
      state.filter(({ id: itemId }) => id !== itemId),
    );
  }

  success(id: string, message: string) {
    this.items.update((state) =>
      state.map((item) => {
        if (item.id === id) {
          return { id, type: 'success', message };
        }

        return item;
      }),
    );
  }

  loadNext() {
    const total = this.total();
    if (total && total <= this.skip + this.limit) {
      return EMPTY;
    }

    this.skip += this.limit;
    return this.loadData();
  }

  setUserFilter(user?: string) {
    this.filter.update((state) => ({ ...state, user }));
  }

  setCategoryFilter(category: TodoCategory) {
    this.filter.update((state) => ({ ...state, category }));
  }

  setUserOption(user: TodosFilterOptions['user']) {
    this.filterOptions.update((state) => ({ ...state, user }));
    this.setUserFilter(user?.id);
  }

  private loadData(filter: TodosFilter = this.filter()) {
    if (this.isLoading()) {
      return EMPTY;
    }

    return this.user.user$.pipe(
      tap(() => this.isLoading.set(true)),
      switchMap((user) => this.loadExtendedTodos({ user, filter })),
      tap(({ total, data }) => {
        if (this.total() == null) {
          this.total.set(total);
        }

        this.items.update((state) => [...state, ...this.transformTodos(data)]);
      }),
      finalize(() => this.isLoading.set(false)),
    );
  }

  private loadExtendedTodos({
    filter,
    user,
  }: {
    filter: TodosFilter;
    user: UserModel;
  }): Observable<{
    total: number;
    data: ExtendedTodoModel[];
  }> {
    const types = todoTypesByCategory[filter.category];

    if (types.length === 0) {
      return of({ data: [], total: 0 });
    }

    let params: CrmDictionary = {
      status: 'active',
      assignee: user._id,
      skip: this.skip,
      limit: this.limit,
      '$sort[priority]': '-1',
      '$sort[endTime]': '1',
      'meta.user': filter.user,
    };

    const sixMonthTodos = intersection(types, [
      'cocm_six_month_health_risk_assessment',
      'cocm_six_month_social_needs_screening',
    ]);

    if (sixMonthTodos.length) {
      const simple = types
        .filter((t) => !sixMonthTodos.includes(t))
        .reduce(
          (result, t, index) => ({
            ...result,
            [`$or[0][type][$in][${index}]`]: t,
          }),
          {},
        );

      const nowPlus31Days = DateTime.now().startOf('day').plus({ day: 31 });

      const sixMonths = sixMonthTodos.reduce(
        (result, t, index) => ({
          ...result,
          [`$or[${index + 1}][type]`]: t,
          [`$or[${index + 1}][endTime][$lt]`]: nowPlus31Days.toISO(),
        }),
        {},
      );

      params = assign(params, { ...simple, ...sixMonths });
    } else {
      params = assign(params, crmFillIn('type', types));
    }

    return this.todo
      .list(params)
      .pipe(
        switchMap((todos) =>
          this.loadRelatedDataAndExtendTodos(todos.data).pipe(
            map((data) => ({ total: todos.total, data })),
          ),
        ),
      );
  }

  private loadRelatedDataAndExtendTodos(
    todos: TodoModel[],
  ): Observable<ExtendedTodoModel[]> {
    const relatedIds = todos.reduce(
      (result, todo) => {
        if (isInitialContactTodo(todo)) {
          result.admissions.push(todo.meta.admission);
          result.users.push(todo.meta.user);
          return result;
        }

        if (isCocmTreatmentPlanTodo(todo)) {
          result.users.push(todo.meta.user);
          return result;
        }

        if (isAssessmentTodo(todo)) {
          result.users.push(todo.meta.user);
          return result;
        }

        if (isTocFollowUpVisitTodoModel(todo)) {
          result.users.push(todo.meta.user);
          result.events.push(todo.event);
          return result;
        }

        if (isRescheduleTelehealthVisitTodoModel(todo)) {
          result.users.push(todo.meta.user);
          result.events.push(todo.event);
          return result;
        }

        if (isScheduledCallTodo(todo)) {
          result.users.push(todo.meta.user);
          result.events.push(todo.event);
          return result;
        }

        if (isCocmScheduleSessionTodo(todo)) {
          result.users.push(todo.meta.user);
          return result;
        }

        return result;
      },
      {
        admissions: [] as string[],
        users: [] as string[],
        events: [] as string[],
      },
    );

    return forkJoin({
      admissions: this.admissions.listSafe(relatedIds.admissions),
      users: this.user.listUsersSafe(relatedIds.users),
      events: this.events.listSafe(relatedIds.events),
    }).pipe(
      map(({ admissions, users, events }) =>
        todos.reduce<ExtendedTodoModel[]>((result, todo) => {
          if (isInitialContactTodo(todo)) {
            result.push(
              this.initialContactTodosResolver.mapToExtendedTodo(
                todo,
                admissions,
                users,
              ),
            );
          }

          if (isCocmTreatmentPlanTodo(todo)) {
            result.push(
              this.cocmTreatmentPlanTodosResolver.mapToExtendedTodo(
                todo,
                users,
              ),
            );
          }

          if (isAssessmentTodo(todo)) {
            result.push(
              this.assessmentTodosResolver.mapToExtendedTodo(todo, users),
            );
          }

          if (isTocFollowUpVisitTodoModel(todo)) {
            result.push(
              this.tocFollowUpVisitTodosResolver.mapToExtendedTodo(
                todo,
                users,
                events,
              ),
            );
          }

          if (isRescheduleTelehealthVisitTodoModel(todo)) {
            result.push(
              this.rescheduleTelehealthVisitTodosResolver.mapToExtendedTodo(
                todo,
                users,
                events,
              ),
            );
          }

          if (isCocmScheduleSessionTodo(todo)) {
            result.push(
              this.cocmScheduleSessionTodosResolver.mapToExtendedTodo(
                todo,
                users,
              ),
            );
          }

          if (isScheduledCallTodo(todo)) {
            result.push(
              this.scheduledCallTodosResolver.mapToExtendedTodo(
                todo,
                users,
                events,
              ),
            );
          }

          return result;
        }, []),
      ),
    );
  }

  private transformTodos(todos: ExtendedTodoModel[]) {
    return todos.reduce<TodoItem<ExtendedTodoModel>[]>((result, todo) => {
      try {
        result.push(this.mapTodoItem(todo) as TodoItem<ExtendedTodoModel>);
      } catch (err) {
        console.error(err);
      }

      return result;
    }, []);
  }

  private mapTodoItem(todo: ExtendedTodoModel) {
    switch (todo.type) {
      case 'reschedule_telehealth_visit':
        return this.rescheduleTelehealthVisitTodosResolver.mapToItem(todo);
      case 'toc_follow_up_visit':
        return this.tocFollowUpVisitTodosResolver.mapToItem(todo);
      case 'toc_initial_contact':
        return this.initialContactTodosResolver.mapToItem(todo);
      case 'cocm_care_plan_bh':
        return this.cocmTreatmentPlanTodosResolver.mapToItem(todo);
      case 'cocm_monthly_gad_7':
      case 'cocm_monthly_phq_9':
      case 'cocm_six_month_health_risk_assessment':
      case 'cocm_six_month_social_needs_screening':
        return this.assessmentTodosResolver.mapToItem(todo);
      case 'cocm_schedule_therapy_session':
      case 'cocm_schedule_psychiatric_session':
        return this.cocmScheduleSessionTodosResolver.mapToItem(todo);
      case 'scheduled_call':
        return this.scheduledCallTodosResolver.mapToItem(todo);
    }
  }

  private close(id: string) {
    const getMessage = (type: TodoModel['type']) => {
      switch (type) {
        case 'scheduled_call':
          return 'todos.scheduledCall.success';
        default:
          return 'Success';
      }
    };

    return this.todo.update(id, { status: 'closed' }).pipe(
      tap((todo) => this.success(id, getMessage(todo.type))),
      catchError(() => EMPTY),
    );
  }
}
