import { TodoModel } from '~/api/todo/model/todo.model';

export const todoCategories = [
  'all',
  'toc',
  'schedule-thv',
  'call',
  'join-thv',
  'forms',
] as const;

export type TodoCategory = (typeof todoCategories)[number];

export const todoTypesByCategory: Record<TodoCategory, TodoModel['type'][]> = {
  all: [
    'toc_initial_contact',
    'toc_follow_up_visit',
    'reschedule_telehealth_visit',
    'cocm_schedule_psychiatric_session',
    'cocm_schedule_therapy_session',
    'cocm_care_plan_bh',
    'cocm_monthly_gad_7',
    'cocm_monthly_phq_9',
    'cocm_six_month_social_needs_screening',
    'cocm_six_month_health_risk_assessment',
    'scheduled_call',
  ],
  toc: ['toc_initial_contact', 'toc_follow_up_visit'],
  call: ['scheduled_call'],
  'schedule-thv': [
    'reschedule_telehealth_visit',
    'cocm_schedule_psychiatric_session',
    'cocm_schedule_therapy_session',
  ],
  'join-thv': [],
  forms: [
    'cocm_care_plan_bh',
    'cocm_monthly_gad_7',
    'cocm_monthly_phq_9',
    'cocm_six_month_social_needs_screening',
    'cocm_six_month_health_risk_assessment',
  ],
} as const;
