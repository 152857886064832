import { inject } from '@angular/core';
import { CrmTranslateService } from 'common-module/translate';
import { DateTime } from 'luxon';
import { catchError, EMPTY, forkJoin, switchMap } from 'rxjs';

import { EventModel } from '~/api/events/event.model';
import { PatientsApiService } from '~/api/patients/patients-api.service';
import { RescheduleTelehealthVisitTodoModel } from '~/api/todo/model/reschedule-telehealth-visit-todo.model';
import { UserModel } from '~/api/user/model/user.model';
import { openScheduleVisitFn } from '~/shared/modal/schedule-visit/open-schedule-visit';
import { formatCustom } from '~/shared/utils/date-time';
import { parseDateTime } from '~/shared/utils/parse-date-time';
import { BaseTodoResolver } from '~/shared/crm/layout/right-sidebar/todos/resolvers/base-todo.resolver';
import { UsersGroupsApiService } from '~/api/user/groups/users-groups-api.service';
import { mapCommunityToOptionValue } from '~/shared/modal/schedule-visit/map-community-to-option';

import { formatAfterExpirationFactory } from '../factories/format-after-expiration.factory';
import { formatBeforeExpirationFactory } from '../factories/format-before-expiration.factory';
import { TodoItem } from '../todo.item';
import { ExtendedRescheduleTelehealthVisitTodoModel } from '../types/reschedule-telehealth-visit.todo';

/**
 * Class which maps reschedule telehealth visit TO-DOs
 */
export class RescheduleTelehealthVisitTodosResolver extends BaseTodoResolver {
  private translate = inject(CrmTranslateService);
  private patient = inject(PatientsApiService);
  private groups = inject(UsersGroupsApiService);
  private formatBeforeExpiration = formatBeforeExpirationFactory();
  private formatAfterExpiration = formatAfterExpirationFactory();
  private scheduleVisit = openScheduleVisitFn();

  mapToExtendedTodo(
    todo: RescheduleTelehealthVisitTodoModel,
    users: UserModel[],
    events: EventModel[],
  ): ExtendedRescheduleTelehealthVisitTodoModel {
    const user = users.find(({ _id }) => _id === todo.meta.user);
    const event = events.find(({ _id }) => _id === todo.event);

    return { ...todo, user, event };
  }

  mapToItem(
    todo: ExtendedRescheduleTelehealthVisitTodoModel,
  ): TodoItem<ExtendedRescheduleTelehealthVisitTodoModel> {
    const { user, event, meta, _id, endTime } = todo;

    if (!user || !event) {
      throw new Error(`No user or event for todo '${todo._id}'`);
    }

    return {
      id: _id,
      type: 'actual',
      user,
      icon: 'schedule-line',
      category: this.translate.get('todos.category.schedule-thv'),
      title: this.translate.get('todos.rescheduleTelehealthVisit.title'),
      messages: [
        {
          message: this.translate.get(
            'todos.rescheduleTelehealthVisit.incomplete',
            {
              date: formatCustom(
                meta.eventStartTime,
                "LLL dd, yyyy 'at' h:mm a",
              ),
            },
          ),
        },
      ],
      expireAt: parseDateTime(endTime),
      resolveStatus: ({ expireAt }) =>
        expireAt < DateTime.now() ? 'overdue' : 'active',
      resolveExpiration: ({ expireAt }) => {
        const now = DateTime.now();

        if (expireAt > now) {
          return this.formatBeforeExpiration(
            expireAt.diff(now, [
              'weeks',
              'days',
              'hours',
              'minutes',
              'seconds',
              'milliseconds',
            ]),
          );
        }

        return this.formatAfterExpiration(
          now.diff(expireAt, [
            'weeks',
            'days',
            'hours',
            'minutes',
            'seconds',
            'milliseconds',
          ]),
        );
      },
      action: {
        id: `reschedule-tv-${todo._id}`,
        title: 'todos.rescheduleTelehealthVisit.action',
        icon: 'icons:schedule-line',
        size: 'small',
        type: 'primary',
        action: () => this.reschedule(_id, user, event),
      },
      dropdownActions: [this.getMarkAsCompletedAction(_id)],
      model: todo,
    };
  }

  private reschedule(id: string, user: UserModel, event: EventModel) {
    const html = document.createElement('div');
    html.innerHTML = event.text ?? '';

    forkJoin({
      community: this.groups.getCommunity(event.community),
      patient: this.patient.getByOwner(user._id),
    })
      .pipe(
        switchMap(({ patient, community }) =>
          this.scheduleVisit({
            title: 'todos.rescheduleTelehealthVisit.title',
            originalEvent: event._id,
            user,
            patient: patient._id,
            admission: event.meta?.admission?._id,
            community: mapCommunityToOptionValue(community),
            serviceType: event.meta?.serviceType?.[0]
              ? { id: '-1', type: event.meta.serviceType[0] }
              : undefined,
            serviceType2: event.meta?.serviceType?.[1]
              ? { id: '-1', type: event.meta.serviceType[1] }
              : undefined,
            serviceType3: event.meta?.serviceType?.[2]
              ? { id: '-1', type: event.meta.serviceType[2] }
              : undefined,
            description: html.innerText,
            disableAdmissionBusChange: true,
            providers: event.participants
              .filter(({ role }) => role !== 'attendee')
              .map(({ ref }) => ref),
          }),
        ),
        catchError(() => EMPTY),
      )
      .subscribe(() =>
        this.provider.success(id, 'todos.rescheduleTelehealthVisit.success'),
      );
  }
}
