import { inject, Injectable } from '@angular/core';
import { CrmActionButtonsConfig } from 'common-module/buttons';
import { CrmMessageService } from 'common-module/message';
import { compact } from 'lodash-es';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { SystemZone } from 'luxon';

import {
  CreateEventAdmissionDto,
  CreateEventDto,
  CreateEventDtoResponse,
  CreateEventServiceOrPatientType,
} from '~/api/events/create-event.dto';
import { EventsApiService } from '~/api/events/events-api.service';
import { PatientsAdmissionsApiService } from '~/api/patients/admissions/patients-admissions-api.service';

import { ModalFormProvider } from '../modal-form.provider';

import { ScheduleVisitFormProvider } from './schedule-visit-form.provider';

@Injectable()
export class ScheduleVisitProvider extends ModalFormProvider<
  CreateEventDtoResponse,
  ScheduleVisitFormProvider
> {
  override formProvider = inject(ScheduleVisitFormProvider);

  private event = inject(EventsApiService);
  private admission = inject(PatientsAdmissionsApiService);
  private message = inject(CrmMessageService);

  protected override getActionsConfig(): Observable<CrmActionButtonsConfig> {
    this.saveTitle = this.data.title ?? 'scheduleVisit.title';
    return super.getActionsConfig();
  }

  protected override makeRequest(): Observable<CreateEventDtoResponse> {
    const {
      community,
      patientType,
      serviceType,
      serviceType2,
      serviceType3,
      start: formStart,
      admission,
      duration,
      description,
      providers,
    } = this.formProvider.formRawValue;

    const invitees = (providers ?? []).reduce<CreateEventDto['invitees']>(
      (result, id) => [...result, { _id: id, eventRole: 'moderator' }],
      [{ _id: this.data.user, eventRole: 'attendee' }],
    );

    const start = formStart!.startOf('minute').setZone(SystemZone.instance, {
      keepLocalTime: true,
    });

    return this.resolveAdmission(admission).pipe(
      switchMap((data) =>
        this.event.create<CreateEventDtoResponse>({
          name: this.getName({
            name:
              serviceType3?.type?.name ??
              serviceType2?.type?.name ??
              serviceType?.type?.name,
            duration,
          }),
          communityId: community.id!,
          duration: duration!,
          startTime: start!.toISO(),
          endTime: start!.plus({ minute: duration! }).toISO(),
          textContent: description ?? undefined,
          htmlContent: description ? `<p>${description}</p>` : undefined,
          invitees,
          meta: {
            originalEvent: this.data.originalEvent,
            admission: data,
            patientType: patientType.type as CreateEventServiceOrPatientType,
            serviceTypes: compact([
              serviceType?.type,
              serviceType2?.type,
              serviceType3?.type,
            ]) as CreateEventServiceOrPatientType[],
          },
        }),
      ),
      tap(() => this.message.success('scheduleVisit.success')),
    );
  }

  private getName({
    name,
    duration,
  }: {
    name?: string | null;
    duration?: number | null;
  }) {
    if (!name || !duration) {
      return 'Telehealth Visit';
    }

    return `${name} - ${duration} min`;
  }

  private resolveAdmission(
    admission?: string | null,
  ): Observable<CreateEventAdmissionDto | undefined> {
    if (!admission) {
      return of(undefined);
    }

    return this.admission.get(admission).pipe(
      map(({ _id, owner, dischargeDate }) => ({
        _id,
        owner,
        dischargeDate,
      })),
    );
  }
}
