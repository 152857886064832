import { UserCommunity } from '~/api/user/groups/user-community';
import { PatientType } from '~/shared/modal/schedule-visit/patient-type';
import { ServiceType } from '~/shared/modal/schedule-visit/service-type';

export type CommunityType = {
  id: string;
  name: string;
  patientTypes: PatientType[];
  serviceTypes: ServiceType[];
  durations: number[];
  isDefault: boolean;
};

export const mapCommunityToOptionValue = ({
  _id,
  settings,
  name,
  default: _default,
}: UserCommunity): CommunityType => {
  return {
    id: _id,
    name,
    patientTypes: (settings?.patientTypes ?? []).map((patientType, index) => ({
      id: String(index),
      type: patientType,
    })),
    serviceTypes: (settings?.serviceTypes ?? []).map((serviceType, index) => ({
      id: String(index),
      type: serviceType,
    })),
    durations: settings?.telehealthVisitDurations ?? [],
    isDefault: _default === 'true',
  };
};
