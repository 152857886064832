import { BaseTodoModel, isTodoModel } from './todo.model';

/**
 * All assessment TO-DO types
 */
export const assessmentTodoTypes = [
  'cocm_monthly_phq_9',
  'cocm_monthly_gad_7',
  'cocm_six_month_health_risk_assessment',
  'cocm_six_month_social_needs_screening',
] as const;

/**
 * Assessment types as TS type
 */
export type AssessmentTodoType = (typeof assessmentTodoTypes)[number];

/**
 * Represents assessment to-do
 */
export type AssessmentTodoModel = BaseTodoModel & {
  type: AssessmentTodoType;
  meta: {
    user: string;
    program: string;
    organization: string;
    record: { type: string };
  };
};

/**
 * Determines if input value is admission to-do model
 *
 * @param value
 */
export const isAssessmentTodo = (
  value: unknown,
): value is AssessmentTodoModel =>
  isTodoModel(value) && assessmentTodoTypes.some((t) => t === value.type);
