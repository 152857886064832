import { inject } from '@angular/core';
import { tap } from 'rxjs';
import { CrmActionButton } from 'common-module/buttons';

import { TodoApiService } from '~/api/todo/todo-api.service';
import { TodosProvider } from '~/shared/crm/layout/right-sidebar/todos/todos.provider';
import { Confirm } from '~/shared/modal/confirm/confirm.decorator';

export abstract class BaseTodoResolver {
  protected todoService = inject(TodoApiService);
  protected provider = inject(TodosProvider);

  protected getMarkAsNotApplicableAction(id: string): CrmActionButton {
    return {
      id: `mark-as-not-applicable-${id}`,
      title: 'todos.markAsNotApplicable.action',
      type: 'text',
      size: 'default',
      action: () => this.markAsNotApplicable(id).subscribe(),
    };
  }

  protected getMarkAsCompletedAction(id: string): CrmActionButton {
    return {
      id: `mark-completed-todo-${id}`,
      title: 'todos.markAsCompleted.action',
      type: 'text',
      size: 'default',
      action: () => this.markAsCompleted(id).subscribe(),
    };
  }

  protected closeTodo(id: string, message: string) {
    return this.todoService
      .update(id, { status: 'closed' })
      .pipe(tap(() => this.provider.success(id, message)));
  }

  @Confirm({
    title: 'todos.markAsCompleted.title',
    message: 'todos.markAsCompleted.message',
  })
  protected markAsCompleted(id: string) {
    return this.closeTodo(id, 'todos.markAsCompleted.success');
  }

  @Confirm({
    title: 'todos.markAsNotApplicable.title',
    message: 'todos.markAsNotApplicable.message',
  })
  protected markAsNotApplicable(id: string) {
    return this.todoService
      .update(id, { status: 'rejected' })
      .pipe(
        tap(() =>
          this.provider.success(id, 'todos.markAsNotApplicable.success'),
        ),
      );
  }
}
